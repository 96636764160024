import React, { useEffect } from "react"
import { Link } from "gatsby"
import {
  makeRem,
  Form,
  FormInput,
  FormSubmit,
  Spinner,
  Text,
} from "@thedgbrt/logology-ui-components"
import { FormError } from "../../common/Form"
import useSignInForm from "./useSignInForm"

const SignIn = ({ initEmail, onNeedVerification, onSuccess }) => {
  let formRef = React.createRef()
  let inputEmailRef = React.createRef()
  let inputPasswordRef = React.createRef()

  const {
    error,
    inputs,
    inputErrors,
    handleInputChange,
    handleSubmit,
    loading,
  } = useSignInForm(initEmail, onSuccess)

  useEffect(() => {
    if (error.resendCode === true)
      onNeedVerification(inputs.email, inputs.password)
  })

  return (
    <>
      {loading ? (
        <Spinner align="left" standalone />
      ) : (
        <>
          <form ref={formRef} style={{ paddingTop: makeRem(12) }}>
            <FormError error={error} />
            <Form standalone>
              <FormInput
                placeholder="My email address&hellip;"
                type="email"
                name="email"
                error={inputErrors.email}
                onChange={e => handleInputChange(e, inputEmailRef)}
                value={inputs.email}
                ref={inputEmailRef}
                required
              />
              <FormInput
                autoComplete="current-password"
                placeholder="My password"
                type="password"
                name="password"
                error={inputErrors.password}
                onChange={e => handleInputChange(e, inputPasswordRef)}
                value={inputs.password}
                ref={inputPasswordRef}
                required
              />
              <FormSubmit
                text="Sign in"
                id="sign-in-submit"
                onClick={e =>
                  handleSubmit(e, [inputEmailRef, inputPasswordRef])
                }
              />
            </Form>
            <Text
              className="small-text"
              standalone
              style={{ textAlign: "center" }}
            >
              <Link
                to={
                  "/forgot-password?email=" + encodeURIComponent(inputs.email)
                }
              >
                I forgot my password
              </Link>
            </Text>
          </form>
        </>
      )}
    </>
  )
}

export default SignIn
