import React, { useState } from "react"
import { connect } from "react-redux"
import {
  GridBox,
  GridContainer,
  Separator,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"
import { getUriParameterByName } from "../../../helpers"
import { cleanLogIn } from "../../../state/actions/user"

import SignInForm from "./SignInForm"
import SignUpWithGoogle from "../SignUp/SignUpWithGoogle"
import SendVerificationCode from "../SendVerificationCode"

const SignIn = ({ initEmail, cleanLogIn }) => {
  const [status, setStatus] = useState("signIn")
  const [email, setEmail] = useState(initEmail)
  const [pw, setPw] = useState("")

  const handleNeedVerification = (email, pw) => {
    setEmail(email)
    setPw(pw)
    setStatus("verification")
  }

  const handleCancelVerification = () => {
    setEmail("")
    setStatus("signIn")
  }

  const handleSuccess = async user => {
    cleanLogIn(user.attributes.email, user.username)
  }

  if (status === "signIn")
    return (
      <>
        <GridBox
          colSpans={[[3, 9], [3, 9], [5, 10]]}
          withBgContainer
          withBorder
          hideBorderRightMobile
          type={5}
        >
          <Title level={3} standalone moreTopSpace>
            Welcome back
          </Title>

          <Standalone style={{ padding: `0` }}>
            <SignUpWithGoogle />
          </Standalone>

          <Standalone>
            <Separator>or</Separator>
          </Standalone>

          <SignInForm
            initEmail={initEmail}
            onNeedVerification={handleNeedVerification}
            onSuccess={handleSuccess}
          />
        </GridBox>
      </>
    )
  else if (status === "verification")
    return (
      <GridContainer
        rowsDesktop={["auto"]}
        rowsMobile={["auto"]}
        rowsTablet={["auto"]}
        type={5}
      >
        <GridBox
          colSpans={[[3, 9], [3, 9], [5, 10]]}
          withBorder
          hideBorderRightMobile
          type={5}
        >
          <SendVerificationCode
            email={email}
            pw={pw}
            onCancel={handleCancelVerification}
            sendRightAway={true}
          />
        </GridBox>
      </GridContainer>
    )
}

export default connect(
  (ownState, ownProps) => {
    const { url } = ownProps
    const initEmail = getUriParameterByName("email", url)

    return { initEmail }
  },
  { cleanLogIn }
)(SignIn)
