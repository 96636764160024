import React from "react"
import SignIn from "../../components/auth/SignIn"
import RedirectRoute from "../../components/auth/RedirectRoute"

const SignInPage = ({ location }) => (
  <RedirectRoute to="/myaccount/proposals/">
    <SignIn url={location.href} />
  </RedirectRoute>
)

export default SignInPage
