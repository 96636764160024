import Auth from "@aws-amplify/auth"
import { useState } from "react"
import { validateInput, validateInputs } from "../../../helpers/validation"
import { trackSegmentEvent } from "../../../helpers/segment"
import {
  trackAmplitude,
  trackAmplitudeIdentity,
} from "../../../helpers/amplitude"
import { trackFBIdentity } from "../../../helpers/fbPixel"

const useSignInForm = (initEmail, successCallback) => {
  const [error, setError] = useState({
    exists: false,
    message: "",
    resendCode: false,
    email: "",
  })

  const [hasValidatedOnce, setHasValidatedOnce] = useState(false)
  const [inputs, setInputs] = useState({ email: initEmail || "", password: "" })
  const [inputErrors, setInputErrors] = useState({ email: "", password: "" })
  const [loading, setLoading] = useState(false)

  const handleInputChange = (event, ref) => {
    event.persist()
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
    // if we already validated the form once (after first submit try),
    // update validation results in real time
    if (hasValidatedOnce) validateInput(ref, setInputErrors)
  }

  const handleError = ({ code, name, message }, email) => {
    let displayMessage
    let resendCode = false

    switch (code) {
      case "UserNotFoundException":
        displayMessage = "Email address does not exist."
        break
      case "NotAuthorizedException":
        displayMessage = "Incorrect password."
        break
      case "UserNotConfirmedException":
        displayMessage = "You need to confirm your email address first."
        resendCode = true
        break
      default:
        displayMessage = message
        break
    }

    setError({ exists: true, message: displayMessage, resendCode, email })
  }

  const handleSubmit = (event, inputRefs) => {
    if (event) {
      event.preventDefault()
      setHasValidatedOnce(true)
    }

    // validate each field individually
    if (validateInputs(inputRefs, setInputErrors) !== true) return

    // try to sign in
    setLoading(true)

    // force lowercase to fix aws email case issue
    const username = inputs.email.toLowerCase().trim()

    Auth.signIn({
      username: username,
      password: inputs.password,
    })
      .then(user => {
        setLoading(false)
        trackSegmentEvent("identify", user.attributes.username, {
          email: user.attributes.email,
        })
        trackSegmentEvent("track", "Signin", { type: "email" })
        trackAmplitudeIdentity(user.attributes.username, user.attributes.email)
        trackAmplitude("Signin", { type: "email" })
        trackFBIdentity(user.attributes.email)

        successCallback(user)
      })
      .catch(err => {
        setLoading(false)
        handleError(err, username)
      })
  }

  return {
    error,
    handleSubmit,
    handleInputChange,
    inputs,
    inputErrors,
    loading,
  }
}

export default useSignInForm
